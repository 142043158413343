<template>
  <v-container fluid>
    <widget-agenda
        :value="value"
        :defaultDisplay="view"
        @update="update"
        @add="add"
        @remove="remove"
    ></widget-agenda>
  </v-container>
</template>

<script>


import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
name: "Agenda",
  components: {
    WidgetAgenda: () => import("@/Components/Views/Home/Widgets/WidgetAgenda")
  },
  computed: {
    locale: function () {
      return 'fr'
    },
    view() {
      let agendaDatas = this.$store.getters.getWidgetDatas("agenda")
      return agendaDatas.preferences != undefined ? agendaDatas.preferences.view : "month"
    },
  },
  methods: {
    update: async function (val) {
      this.$store.commit("updateWidgetDatas", val)
      await this.saveStore()
    },
    add: async function (val) {
      this.$store.commit("addWidgetDatas", val)
      await this.saveStore()
    },
    remove: async function (val) {
      this.$store.commit("deleteWidgetDatas", val)
      await this.saveStore()
    },
    saveStore: async function () {
      let store = this.$_.pick(this.$store.state, ["widgetBoard"])
      let response = await PlaneteOnlineService.postInterface(store)
    }
  },
  data() {
    return {
      value: null,
      type: "week",
      fab: true,
    }
  },
}
</script>

<style scoped>

</style>